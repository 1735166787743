import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { DownCircleOutlined } from '@ant-design/icons';
import { ChatContainer, MainContainer, Message, MessageList, TypingIndicator } from '@chatscope/chat-ui-kit-react';
import './ChatCSS.css';
import { useChat } from './ChatProvider';
import { commonMessageModel, MessageDirection, MessageSender } from './messageBuilder/ChatMessageBuilder';
import { EmptyChatComponent } from './EmptyChatContent';
import { ChatInput } from './chatInput/ChatInput';

const CenteredTypingIndicator = styled(TypingIndicator)`
    margin: 0 20%;
`;

const ScrollToBottomButton = styled.div`
    position: absolute;
    margin-top: -0.5rem;
    left: 50%;
    z-index: 10;
    color: rgba(0, 0, 0, 0.85);
    &:hover {
        color: #40a9ff;
        cursor: pointer;
    }
`;

export const DukeChatContainer = () => {
    const { messageList, typingIndicator } = useChat();
    const userInputRef = useRef(null);
    const [chatContainerHeight, setChatContainerHeight] = useState<string>('100%');
    // eslint-disable-next-line
    const [isAtBottom, setIsAtBottom] = useState(true);
    const chatContainerRef = useRef(null);
    const messageListRef = useRef(null);

    useEffect(() => {
        /** Resizer */
        const resizeChatContainer = () => {
            const inputContainerHeight = (userInputRef?.current as any).input.parentElement.parentElement.parentElement
                .parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.offsetHeight;
            setChatContainerHeight(`${window.innerHeight - inputContainerHeight - 70}px`);
        };
        resizeChatContainer();
        window.addEventListener('resize', resizeChatContainer);

        /** Listen to scroll */
        const handleScroll = () => {
            const scrollContainer = (chatContainerRef?.current as any).children[0].children[0].children[0].children[0];
            // Déterminez si vous êtes au bas de cet élément en comparant
            // la hauteur totale de l'élément avec la hauteur de l'élément plus le défilement vertical actuel.
            // On ajoute -5px comme parge d'erreur
            const isBottom =
                scrollContainer.scrollHeight - scrollContainer.scrollTop - 5 <= scrollContainer.clientHeight;
            setIsAtBottom(isBottom);
        };
        const scrollContainer = (chatContainerRef?.current as any).children[0].children[0].children[0].children[0];
        scrollContainer.addEventListener('scroll', handleScroll);

        /** Clean */
        return () => {
            window.removeEventListener('resize', resizeChatContainer);
            scrollContainer.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScrollToBottom = () => {
        (messageListRef?.current as any).scrollToBottom();
    };

    return (
        <>
            <div ref={chatContainerRef}>
                <MainContainer style={{ border: 'none', borderBottom: '1px solid rgb(233, 233, 233)' }}>
                    <ChatContainer style={{ height: chatContainerHeight }}>
                        <MessageList
                            style={{ padding: '0rem 20%' }}
                            typingIndicator={typingIndicator && <CenteredTypingIndicator />}
                            ref={messageListRef}
                        >
                            {messageList?.length
                                ? messageList
                                : [
                                      commonMessageModel(
                                          {
                                              sender: MessageSender.Bot,
                                              direction: MessageDirection.Outgoing,
                                              type: 'custom',
                                              message: '',
                                          },
                                          <Message.CustomContent>
                                              <EmptyChatComponent />
                                          </Message.CustomContent>,
                                      ),
                                  ]}
                        </MessageList>
                    </ChatContainer>
                </MainContainer>
                {!isAtBottom && (
                    <ScrollToBottomButton onClick={handleScrollToBottom}>
                        <DownCircleOutlined
                            style={{
                                background: 'white',
                                fontSize: 'medium',
                            }}
                        />
                    </ScrollToBottomButton>
                )}
            </div>
            <ChatInput userInputRef={userInputRef} />
        </>
    );
};
