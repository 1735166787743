import React from 'react';
import { UploadFile } from 'antd/es/upload/interface';
import { PaperClipOutlined } from '@ant-design/icons';
import { Badge, Button } from 'antd';
import '../ChatCSS.css';

interface Props {
    fileList: UploadFile[];
    setShowFileModal: (param: any) => void;
}

export const ChatInputImportButton = ({ fileList, setShowFileModal }: Props) => {
    return (
        <Badge count={fileList?.length} color="#40A9FF">
            <Button type="text" onClick={() => setShowFileModal(true)}>
                <PaperClipOutlined
                    style={{
                        fontSize: 16,
                    }}
                />
            </Button>
        </Badge>
    );
};
