import React, { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import { useAppConfig } from '../../useAppConfig';
import { buildBotResponse, buildSuggestionsMessage, buildUserMessage } from './messageBuilder/ChatMessageBuilder';

interface ChatState {
    messageList: any[];
    typingIndicator: boolean;
    sendMessage: (message: string, intention?: number, fileList?: any[], displayMessage?: boolean) => void;
    dukeAction: (formData: FormData) => Promise<Response>;
}

const defaultChatState: ChatState = {
    messageList: [],
    typingIndicator: false,
    sendMessage(): void {},
    dukeAction(): Promise<Response> {
        return new Promise<Response>(() => null);
    },
};

const ChatContext = createContext(defaultChatState);

export const useChat = () => useContext(ChatContext);

const CHAT_LENGTH = 50;

/* eslint-disable react/prop-types */
export const ChatProvider = ({ children }) => {
    const appConfig = useAppConfig();
    const { i18n } = useTranslation();
    const [messageList, setMessageList] = useState<any[]>([]);
    const [chatUrl, setChatUrl] = useState<any>(appConfig?.config?.dukeConfig?.dukeChatUrl);
    const [typingIndicator, setTypingIndicator] = useState<boolean>(false);

    useEffect(() => {
        const dukeChatUrl: string = appConfig?.config?.dukeConfig?.dukeChatUrl || '';
        setChatUrl(dukeChatUrl === 'default' ? 'http://127.0.0.1:8002' : dukeChatUrl);
    }, [appConfig, setChatUrl]);

    const fetchDuke = (path, bodyFormData) => {
        return new Promise((resolve, reject) => {
            fetch(`/dtk`, {
                headers: {
                    'Cache-Control': 'no-cache',
                },
            })
                .then((r) =>
                    r.json().then((dtk) => {
                        fetch(`${chatUrl}/${path}`, {
                            body: bodyFormData,
                            method: 'POST',
                            headers: {
                                Authorization: `Bearer ${dtk.token}`,
                            },
                            referrerPolicy: 'no-referrer',
                        })
                            .then((response) => {
                                if (response.status === 200) {
                                    resolve(response);
                                } else {
                                    const errorMessage = `${response.status} - ${response.statusText}`;
                                    message.error(errorMessage);
                                    reject(errorMessage);
                                }
                            })
                            .catch((error) => reject(error));
                    }),
                )
                .catch((error) => reject(error));
        });
    };

    const askDuke = (formData) => {
        formData.append('lang', i18n.language);
        return fetchDuke('chat', formData);
    };

    const dukeAction = (formData) => {
        return fetchDuke('action', formData);
    };

    const sendMessage = (
        userInput: string,
        intention?: number,
        fileList?: any[],
        displayMessage: boolean | undefined = true,
    ) => {
        if (!userInput && !fileList?.length && !intention) {
            return;
        }
        setTypingIndicator(true);
        if (displayMessage) {
            setMessageList((ml) => [...ml, buildUserMessage(userInput)].slice(-CHAT_LENGTH));
        }

        const formData = new FormData();
        formData.append('message', userInput);
        if (fileList?.length) {
            // Ajouter chaque fichier dans l'objet FormData
            for (let i = 0; i < fileList.length; i++) {
                formData.append('file', fileList[i]);
            }
        }
        if (intention) {
            formData.append('intention', intention.toString());
        }

        askDuke(formData)
            .then((r: any) =>
                r
                    .json()
                    .then((dukeResponse) => {
                        setTypingIndicator(false);
                        setMessageList((ml) => {
                            const result: JSX.Element[] = [buildBotResponse(dukeResponse)];
                            if (dukeResponse.suggestionList?.length && Array.isArray(dukeResponse.suggestionList)) {
                                result.push(
                                    buildSuggestionsMessage(dukeResponse.suggestionList, sendMessage, fileList),
                                );
                            }
                            return [...ml, ...result].slice(-CHAT_LENGTH);
                        });
                    })
                    .catch((e) => {
                        console.log(e);
                        setTypingIndicator(false);
                    }),
            )
            .catch((e) => {
                console.log(e);
                setTypingIndicator(false);
            });
    };

    return (
        <ChatContext.Provider
            value={{
                ...defaultChatState,
                ...{ messageList, typingIndicator, sendMessage, dukeAction },
            }}
        >
            {children}
        </ChatContext.Provider>
    );
};
