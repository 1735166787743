import React, { useState } from 'react';
import { message, Button, Input, Modal, Typography, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useUpdateUserPasswordMutation } from '../../../graphql/duke.generated';
import { UpdateUserPasswordInput } from '../../../types.generated';

type UpdateUserPasswordModalProps = {
    visible: boolean;
    onClose: () => void;
    displayName: string;
    username: string;
};

export default function UpdateUserPasswordModal({
    username,
    displayName,
    visible,
    onClose,
}: UpdateUserPasswordModalProps) {
    const [stagedActualPassword, setStagedActualPassword] = useState('');
    const [stagedNewPassword, setStagedNewPassword] = useState('');
    const [stagedNewPasswordBis, setStagedNewPasswordBis] = useState('');
    const [updateUserPasswordMutation] = useUpdateUserPasswordMutation();
    const { t } = useTranslation();

    const onUpdateUserPassword = () => {
        const updateUserPasswordInput: UpdateUserPasswordInput = {
            username,
            actualPassword: stagedActualPassword,
            newPassword: stagedNewPassword,
        };
        updateUserPasswordMutation({
            variables: {
                input: updateUserPasswordInput,
            },
        })
            .catch((e) => {
                message.destroy();
                message.error({
                    content: `${t('user.updateUserPasswordModal.updateFailed', { displayName })} \n ${e.message || ''}`,
                    duration: 3,
                });
            })
            .finally(() => {
                message.success({
                    content: t('user.updateUserPasswordModal.updateSuccess'),
                    duration: 3,
                });
                setStagedActualPassword('');
                setStagedNewPassword('');
                setStagedNewPasswordBis('');
            });
        onClose();
    };

    return (
        <Modal
            title={t('user.updateUserPasswordModal.title')}
            visible={visible}
            onCancel={onClose}
            footer={
                <>
                    <Button onClick={onClose} type="text">
                        {t('common.cancel')}
                    </Button>
                    <Button
                        onClick={onUpdateUserPassword}
                        disabled={
                            stagedActualPassword === '' ||
                            stagedNewPassword === '' ||
                            stagedNewPassword !== stagedNewPasswordBis
                        }
                    >
                        {t('crud.update')}
                    </Button>
                </>
            }
        >
            <Form layout="vertical">
                <Form.Item
                    name="actualPassword"
                    label={<Typography.Text strong>{t('user.updateUserPasswordModal.usernameLabel')}</Typography.Text>}
                >
                    <Typography.Paragraph>
                        {t('user.updateUserPasswordModal.usernameDescription')}
                        <b>{displayName}</b>
                    </Typography.Paragraph>
                </Form.Item>
                <Form.Item
                    name="fullName"
                    label={
                        <Typography.Text strong>
                            {t('user.updateUserPasswordModal.actualPasswordLabel')}
                        </Typography.Text>
                    }
                >
                    <Typography.Paragraph>
                        {t('user.updateUserPasswordModal.actualPasswordDescription')}
                    </Typography.Paragraph>
                    <Input.Password
                        required
                        placeholder={t('user.updateUserPasswordModal.actualPasswordPlaceholder')}
                        value={stagedActualPassword}
                        onChange={(event) => setStagedActualPassword(event.target.value)}
                    />
                </Form.Item>
                <Form.Item
                    name="newPassword"
                    hasFeedback
                    label={
                        <Typography.Text strong>{t('user.updateUserPasswordModal.newPasswordLabel')}</Typography.Text>
                    }
                >
                    <Typography.Paragraph>
                        {t('user.updateUserPasswordModal.newPasswordDescription')}
                    </Typography.Paragraph>
                    <Input.Password
                        required
                        placeholder={t('user.updateUserPasswordModal.newPasswordPlaceholder')}
                        value={stagedNewPassword}
                        onChange={(event) => setStagedNewPassword(event.target.value)}
                    />
                </Form.Item>
                <Form.Item
                    name="newPasswordBis"
                    dependencies={['newPassword']}
                    hasFeedback
                    rules={[
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error(t('user.updateUserPasswordModal.passwordDontMatch')));
                            },
                        }),
                    ]}
                >
                    <Typography.Paragraph>
                        {t('user.updateUserPasswordModal.newPasswordBisDescription')}
                    </Typography.Paragraph>
                    <Input.Password
                        required
                        placeholder={t('user.updateUserPasswordModal.newPasswordBisPlaceholder')}
                        value={stagedNewPasswordBis}
                        onChange={(event) => setStagedNewPasswordBis(event.target.value)}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
}
