import React, { Ref, useCallback, useState } from 'react';
import { UploadFile } from 'antd/es/upload/interface';
import styled from 'styled-components/macro';
import { SendOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, InputRef } from 'antd';
import '../ChatCSS.css';
import { useChat } from '../ChatProvider';
import UploadFileModal from '../modal/uploadFileModal';
import { ChatInputImportButton } from './ChatInputImportButton';

const DukeInput = styled(Input)`
    margin: 2rem 0 auto 0;
`;

const ChatWarning = styled.span`
    font-style: italic;
    font-size: smaller;
    padding-left: 5rem;
    font-weight: 100;
    &:before {
        content: '*';
    }
`;

const userInputFieldName = 'userInput';

interface Props {
    userInputRef?: Ref<InputRef>;
}

export const ChatInput = ({ userInputRef }: Props) => {
    const { sendMessage } = useChat();
    const [form] = Form.useForm();
    const [showFileModal, setShowFileModal] = useState<boolean>(false);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const { t } = useTranslation();

    const focusOnInput = useCallback(() => {
        if (userInputRef) {
            const userInputElt: any = (userInputRef as any)?.current;
            userInputElt?.focus();
            setTimeout(() => {
                if (userInputElt) {
                    userInputElt?.focus();
                }
            }, 500);
        }
    }, [userInputRef]);

    const handleCloseModal = useCallback(() => {
        setShowFileModal(false);
        focusOnInput();
    }, [setShowFileModal, focusOnInput]);

    const handleSaveFileList = (uploadFileList: UploadFile[]) => {
        setFileList(uploadFileList);
        // On ne fais la description que s'il n'y a qu'un seul fichier.
        if (uploadFileList?.length === 1) {
            // TODO ndespouy set an enum of CHAT_INTENTION
            sendMessage('', 4, uploadFileList, false);
        }
    };

    const handleSendMessage = useCallback(() => {
        const userInput = form.getFieldValue(userInputFieldName);
        if (userInput) {
            // TODO ndespouy set an enum of CHAT_INTENTION
            sendMessage(userInput, fileList?.length && 2, fileList);
            form.resetFields([userInputFieldName]);
        }
    }, [sendMessage, fileList, form]);

    const inputSuffix = (
        <Button type="text" onClick={handleSendMessage}>
            <SendOutlined
                style={{
                    fontSize: 16,
                }}
            />
        </Button>
    );

    const importFileButton = <ChatInputImportButton fileList={fileList} setShowFileModal={setShowFileModal} />;

    return (
        <>
            <Form form={form} labelCol={{ offset: 5 }} wrapperCol={{ offset: 5, span: 14 }} layout="vertical">
                <Form.Item name={userInputFieldName} style={{ marginBottom: 0 }}>
                    <DukeInput
                        autoFocus
                        addonBefore={importFileButton}
                        suffix={inputSuffix}
                        onPressEnter={handleSendMessage}
                        ref={userInputRef}
                    />
                </Form.Item>
                <Form.Item
                    style={{ height: 50, marginBottom: 0 }}
                    label={<ChatWarning>{t('dukeChat.warning')}</ChatWarning>}
                />
            </Form>
            <UploadFileModal
                visible={showFileModal}
                onSaveFileList={handleSaveFileList}
                fileList={fileList}
                onClose={handleCloseModal}
            />
        </>
    );
};
