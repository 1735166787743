import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Button, Modal, Typography } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import Markdown from 'react-markdown';
import { ChatChart } from './ChatChart';
import { MessageTooltip } from './component/MessageTooltip';

const ObsButton = styled(Button)`
    float: right;
    display: flex;
    align-items: flex-end;
    padding-left: 5px;
`;

type Props = {
    messageParams: any;
};

export const NormalMessageComponent = ({ messageParams }: Props) => {
    const { t } = useTranslation();
    const [showObservability, setShowObservability] = useState(false);

    const onClose = () => setShowObservability(false);

    return (
        <>
            {messageParams.tooltip && <MessageTooltip title={messageParams.tooltip} />}
            {!!messageParams?.observability?.length && (
                <ObsButton type="text" onClick={() => setShowObservability(true)}>
                    <Badge style={{ margin: '-17px -9px 0 0' }} count={<EyeOutlined />} />
                </ObsButton>
            )}
            <Typography.Title style={{ fontSize: '20px' }}>
                {parseInt(messageParams.intention, 10) === 4 ? t('common.description') : t('common.answer')}
            </Typography.Title>
            <p>
                <Markdown>{messageParams.answer}</Markdown>
            </p>
            {!!messageParams?.chart_data && (
                <p>
                    <ChatChart chartParams={messageParams.chart_data} />
                </p>
            )}
            {!!messageParams?.observability?.length && (
                <Modal
                    open={showObservability}
                    title={t('adminHeader.analyticsText')}
                    onCancel={onClose}
                    okText={t('common.ok')}
                    width={800}
                    cancelButtonProps={{ type: 'ghost' }}
                >
                    {messageParams.observability
                        .filter((obs) => obs.content)
                        .map((obs) => (
                            <>
                                <Typography.Title style={{ fontSize: 'smaller' }}>
                                    {obs.name} ({obs.source})
                                </Typography.Title>
                                <Typography.Text>
                                    <pre>{obs.content}</pre>
                                </Typography.Text>
                            </>
                        ))}
                </Modal>
            )}
        </>
    );
};
